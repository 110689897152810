.products {
  
  padding: 0 0 120px 240px;
  
  @include media ($tablet){ 
   padding: 90px 0 120px 0;
  }
  
  .related-products &{
    padding: 0;
  }
  
  .product {
    text-align: center;
    margin-top: 70px;
    max-width: 300px;
    width: 300px;
    
    @include media($mobile){
      &:first-child {
        margin-top: 0px;
      }

    }
    
    h3 {
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
      margin: 1em auto .5em;
      max-width: 220px;
      min-height: 64px;
      
    }
    
    .onsale {
      display: block;
      height: 0px;
      line-height: 30px;
      box-sizing: border-box;
      color: $white;
      background: $black;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 300;
      margin: 0 auto;
      position: relative;
      transform: translateY(-40px);
              
      span {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: $black;
        padding: 0 12px;
      }
    }
  }
  
  .woocommerce-LoopProduct-link {
    
    display: block;
    
    .price {
      font-size: 14px;
      font-weight: 300;
    }
    
    
  }
  
  .added_to_cart {
    @include hide-text;
    display: inline-block;
    margin: 1em auto;
    vertical-align: top;
    width: 40px;
    height: 40px;
    background:#000 url('/images/cart.svg') no-repeat center center;
    background-size: 16px 16px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  
  
}

.ajax_add_to_cart{
  display: inline-block;
  width: 140px;
  height: 40px;
  margin: 1em auto;
  vertical-align: top;
  line-height: 40px;
  background: #aaa;
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  transition: width .3s;
}

.add_to_cart_button , .single_add_to_cart_button{
  
  display: inline-block;
  width: 140px;
  height: 40px;
  margin: 1em auto;
  vertical-align: top;
  line-height: 40px;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  transition: width .3s;
  
  &.loading {
    width: 100px;
    
  }
  
  &.added {
    width: 100px;
    
  }

}







html {
  font-size: 62.5%;
}

body {
  height: 100%;
  width: 100%;
  color: $black;
  background: $white;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;
  font-family: $body-font;
  padding-top: 0.01em;

}

p {
  display: block;
}

#symbols {
  display: none;
}

a {
  text-decoration: none;
  color: $black;
  @include transition(all .5s);
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  background: none;
  border: none;
  @include appearance(none);
  padding: 0;
  margin: 0;
  
  &:focus {
    outline: none;
  }
}

svg {
  width: 100%;
  height: 100%;
}

figure {
  margin: 0;
  padding: 0;
}
.icono-stroke{
	border: $U2 solid;
}

.stickCenterV{
    @include stickCenterV;
}

.stickCenterH{
    @include stickCenterH;
}

.stickCenter{
    @include stickCenter;
}

.spin[class*="spin"]{
    animation: loading-spinner 2s infinite linear;
}
@keyframes loading-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.about-header {
  font-size: 14px;
  font-weight: 300;
  
  h3 {
    font-weight: 700;
  }
  
  h1 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .date {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
  }
}

.about-col {
  height: 100%;
  
  
}

.about-text {
  height: 100%;
  position: relative;
  
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 20px 12px 0px #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    margin-top: -5px;
    
    @include media($tablet){ 
      display: none;
    }
  }
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -10px 17px 0px #fff;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
    
    @include media($tablet){ 
      display: none;
    }
  }
}

.ss-wrapper {
  overflow : hidden;
  width    : 100%;
  height   : 100%;
  position : relative;
  z-index  : 1;
  float: left;
}

.ss-content {
  height          : 100%;
  width           : 100%;
  padding         : 0 32px 0 0;
  position        : relative;
  right           : -18px;
  overflow        : auto;
  box-sizing      : border-box;
  
  @include media($tablet){
    right           : 0;
    padding         : 0 0 0 0;
  }
}

.ss-scroll {
  position            : relative;
  background          : $black;
  width               : 3px;
  top                 : 0;
  z-index             : 3;
  cursor              : pointer;
  opacity: 0;
  transition: opacity 0.25s linear;
}

.ss-hidden {
  display: none;
}

.ss-container:hover .ss-scroll {
  opacity: 1;
}

.ss-grabbed {
  user-select: none;
}
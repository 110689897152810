.logo {
  width: 400px;
  height: 124px;
  position: fixed;
  top: 32px;
  left: 32px;
  z-index: 99;
  transition: transform .31s cubic-bezier(.91,.01,.6,.99);
  transform: translate3d(0, 0, 0);
  
  .has-menu & {
  	transform: translate3d(240px, 0, 0);
  	transition: transform .31s cubic-bezier(.91,.01,.6,.99);
  	@include media($mobile){ 
  	  transform: translate3d(50vw, 0, 0);
  	}
  }
  
  .has-collection &{
    transform: translate3d(440px, 0, 0);
    transition: transform .31s cubic-bezier(.91,.01,.6,.99);
    @include media($mobile){ 
      transform: translate3d(100vw, 0, 0);
    }
  }
  
  
  @include media($tablet){
    width: 260px;
    height: 81px;
  };
  
  @include media($mobile){
    width: 160px;
    height: 50px;
    top: 20px;
    left: 20px;
  };
  
}

.navigation-wrapper {
  display: flex;
  
  @include media($mobile){ 
    display: block;
    width: 150vw;
    overflow: hidden;
  }
  
}

.main-nav, .sub-nav {
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  a {
    display: block;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2;
    padding: 0 0 0 5rem;
    
    &.disabled {
      text-decoration: line-through;
    }
    
    
    @include media($mobile){ 
      font-size: 16px;
      padding: 0 0 0 2rem;
    }
    
  }
  
  .shop-extra {
    display: none;
    
    @include media($tablet){
      display: block;
    }
    
  }
  
  .separator {
    display: block;
    border-top: 1px solid #000;
    margin: 24px 5rem;
    
    @include media($mobile){ 
      margin: 24px 2rem;
    }
  }
  
}

.main-nav {
  width: 240px;
  
  @include media($mobile){
    width: 50vw;
    float: left;
  }
}

.sub-nav {
  width: 200px;
  border-left: 1px solid $darkgrey;
  
  @include media($mobile){
    width: 50vw;
    float: left;
  }
  
  a {
    font-weight: 400;
    padding: 0 0 0 5rem;
    
    @include media($tablet){
      font-size: 16px;
      padding: 0 0 0 3.7rem;
    }
    
    @include media($mobile){
      font-size: 15px;
      padding: 0 0 0 12px;
    }
  }
  
  .has-categories & {
    transform: translate3d(100%, 0, 0);
  }
}

.sub-categories {
  width: 200px;
  border-left: 1px solid $darkgrey;
  
  @include media($mobile){
    width: 50vw;
    float: left;
  }
  
  .has-categories & {
    transform: translate3d(-100%, 0, 0);
  }
  
  a {
    font-weight: 400;
  }
}


.collection-nav {
    
    display: inline-block;
    
    a {
      display: block;
      font-size: 20px;
      font-weight: 300;
      
      @include media($mobile){
        font-size: 16px;
      }
      
      span {
        display: inline-block; 
        
        &:after {
          content: '';
          height: 1px;
          display: block;
          width: 100%;
          background: $black;
          
          
          
        }
      }
      
    }
    

}



.marker {

  position: absolute;
  bottom: 32px;
  left: 62px;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  transition: visibility .31s, opacity .31s, transform .31s cubic-bezier(.91,.01,.6,.99);
  transform: translate3d(0, 0, 0);  
  
  .has-menu & {
  	transform: translate3d(240px, 0, 0);
  	transition: visibility .31s, opacity .31s, transform .31s cubic-bezier(.91,.01,.6,.99);
  	
  	@include media($mobile){
  	  transform: translate3d(50vw, 0, 0);
  	  display: none;
  	}
  }
  
  .has-collection &{
    transform: translate3d(440px, 0, 0);
    transition: visibility .31s, opacity .31s, transform .31s cubic-bezier(.91,.01,.6,.99);
    
    @include media($mobile){
      transform: translate3d(100vw, 0, 0);
      display: none;
    }
  }
  
  &.is-hide {
    visibility: hidden;
    opacity: 0;
  }
  
  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  span {
    display: block;
  }
  
  .marker-info {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    transform-origin: left bottom;
    transform:translateX(-2px) rotate(-90deg);
    line-height: .9;
  }
  
  .arrow {
    width: 78px;
    height: 41px;
    margin-left: 8px;
  }

}


.switch {
  position: fixed;
  left: 32px;
  top: 40%;
  width: 48px;
  height: 48px;
  z-index: 99;
  transform: translate3d(0, -50%, 0);
  transition: transform .31s cubic-bezier(.91,.01,.6,.99);
  
  @include media ($mobile){  
    width: 32px;
    height: 32px;
  }
  
  &.is-active  {
  	transform: translate3d(240px, -50%, 0);
  	
  	@include media ($tablet){ 
  	transform: translate3d(0, -50%, 0);
  	};
  	
  	@include media ($mobile){ 
  	transform: translate3d(0, -50%, 0);
  	left: 26px;
  	};
  	
  	.has-collection &{
  	  transform: translate3d(440px, -50%, 0);
  	  
  	  @include media ($tablet){ 
  	  transform: translate3d(0, -50%, 0);
  	  
  	  };
  	  
  	  @include media ($mobile){ 
  	  transform: translate3d(0, -50%, 0);
  	  left: 26px;
  	  };
  	}
  }
  
  @include media ($tablet){ 
    left: auto;
    top: 56px;
    right: 32px;
  };
  
  @include media ($mobile){ 
    left: auto;
    top: 47px;
    right: 20px;
  };
  
}


.categories {
  
  margin: 24px 0;
  
  @include media ($tablet){  
    float: right;
    margin: 0;
    display: none;
  }
  
  .cat-item {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    
    &.current-cat {
      a {
        text-decoration: underline;
      }
    }
  }
}

.shop-aside {
  
  position: fixed;
  top: 75px;
  left: 32px;
  z-index: 99;
  transition: transform .31s cubic-bezier(.91,.01,.6,.99);
  transform: translate3d(0, 0, 0);
  
  &.is-active  {
  	transform: translate3d(240px, 0, 0);
  	
  	@include media($mobile){
  	  transform: translate3d(50vw, 0, 0);
  	}
  	
  	.has-collection &{
  	  transform: translate3d(440px, 0, 0);
  	  @include media($mobile){
  	    transform: translate3d(100vw, 0, 0);
  	  }
  	}	
  }
  
  @include media ($tablet){ 
    width: 100%;
    top: 0;
    left: 0px;
    padding: 30px;
    
  }
  
  @include media ($mobile){ 
    
    padding: 20px;
    
  };

  .logo {
    position: relative;
    top: auto;
    left: auto;
    z-index: 99;
    width: 160px;
    height: 50px;
    transform: translate3d(0, 0, 0);
    
    .has-menu & {
    	transform: translate3d(0, 0, 0);
    }
    
    .has-collection &{
      transform: translate3d(0, 0, 0);
    }
    
    @include media ($tablet){ 
      float: left;
    }
  }
  
  .switch {
    position: relative;
    left: auto;
    top: auto;
    transform: translate3d(0, 0, 0);
    
    @include media ($tablet){  
      position: fixed;
      top: 32px;
    };
    
    @include media ($mobile){ 
      
      top: 22px;
      
    };
    
    
    &.is-active  {
    	
    	transform: translate3d(0, 0, 0);
    	
    	@include media ($tablet){  
    	  transform: translate3d(-240px, 0, 0);
    	
    	}
    	
    	@include media ($mobile){  
    	  transform: translate3d(-50vw, 0, 0);
    	  left: 26px;
    	
    	}
    	
    	.has-collection &{
    	  //transform: translate(440px, 0);
    	  transform: translate3d(0, 0, 0);
    	  
    	  @include media ($tablet){  
    	    transform: translate3d(-440px, 0, 0);
    	
    	  }
    	  
    	  @include media ($mobile){  
    	    transform: translate3d(-100vw, 0, 0);
    	    left: 24px;
    	  }
    	  
    	}
    }
  }
  
  
}


.shop-menu {
  color: $white;
  background: $black;
  height: 30px;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  width: 100%;
  box-sizing: border-box;
  padding:0 32px;
  transform: translate3d(0, 0, 0);
  
  @include media ($tablet){ 
    display: none;
  }
  
  .cart-svg {
    margin: 1px 8px 0 0;
    width: 18px;
    height: 16px;
    display: block;
    float: left;
  
    margin: 5px 4px 0 0;
    line-height: 22px;
  }
  
  a {
    color: $white;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .06em;
    margin: 0 12px 0 0;
    line-height: 30px;
  }
  
  .cart-contents {
    margin-right: 0;
    letter-spacing: .06em;
    
    
  } 
  
  .cart-preview {
    float: right;
    font-size: 12px;
    letter-spacing: .06em;
    line-height: 30px;
    
    .cart-icon {
      line-height: 24px;
    }
    
    
    /*@include media ($tablet){ 
      display: none;
    }*/

  }
  
}
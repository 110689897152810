#video-modal {  
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 0%;
  height: 100vh;
  overflow: hidden;
  transition: width 0s linear .51s;
  
  .modal-background {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 1;
    transition: opacity 1s cubic-bezier(.59,.01,.28,1) ;
    opacity: 0;
    transform: translate3d(0, 0, 0);

  }
  
  &.has-video {
    width: 100%;
    transition: width 0s linear 0s;
    
    .cross {
      transform: translate3d(0, 0, 0);
    }
    
    .modal-background {
      opacity: .7;
    }
    
  }
}

.collection-video {
  display: block;
  transform-origin: right top; 
  height: 100vh;
  width: 100%;
  background: $black;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 998;
  padding: 20px;
  transition:transform .51s cubic-bezier(.59,.01,.28,1);
  transform: translate3d(100%, 0, 0);
  
  .video-container {
    height: 100%;
    background: #111;
  }
  
  .has-video &{
    transform: translate3d(0, 0, 0);
  }
  
}


.cross {
  display: inline-block;
  width: 36px;
  height: 36px;
  position: absolute;
  z-index: 1999;
  color: $white;
  top: 20px;
  right: 20px;
  transition: color .3s, transform .51s cubic-bezier(.59,.01,.28,1);
  transform: translate3d(200px, 0, 0);
  
  &:hover {
    color: $grey;
  }
}



#gallery-modal {
  position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 0%;
    height: 100vh;
    overflow: hidden;
    transition: width 0s linear .51s;
    
    .cross {
      color: $black;
    }
    
    .modal-background {
      width: 100%;
      height: 100%;
      background: $grey;
      opacity: 1;
      transition: opacity 1s cubic-bezier(.59,.01,.28,1) ;
      opacity: 0;
      transform: translate3d(0, 0, 0);
  
    }
    
    &.has-gallery {
      width: 100%;
      transition: width 0s linear 0s;
      
      .cross {
        transform: translate3d(0, 0, 0);
      }
      
      .modal-background {
        opacity: .7;
        
      }
      
    }
}

.gallery {
  display: block;
  transform-origin: right top; 
  height: 100vh;
  width: 58%;
  background: $grey;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 998;
  transition:transform .51s cubic-bezier(.59,.01,.28,1);
  transform: translate3d(100%, 0, 0);
  
  .gallery-container {
    height: 100%;
    background: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .has-gallery &{
    transform: translate3d(0, 0, 0);
  }
  
  @include media($tablet){
    width: 100%;
  }
  
}

.slider-nav {
  width: 41px;
  height: 78px;
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  color: $black;
  
  &.arrow-left {
    left: 20px;
  }
  
  &.arrow-right {
    right: 20px;
  }
  
  @include media($mobile){
    height: 36px;
  }
  
}


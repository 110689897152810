.collection-rows {
  overflow: hidden;
  
  @include media ($tablet){ 
    padding: 0 16px;
  }
}

.row {
  padding: 0;
  position: relative;
  
  height: 0;
  padding-bottom: 56.25%;
  
  
  .row-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  &.single {
   // height: 70vmin;
    figure {
      width: 100%;
      
      &.right {
        justify-content: flex-end;
      }
      &.left {
        justify-content: flex-start;
      }
      
      .img {
        width: 70%;
      }
    }
    
  }
  
  &.double {
    figure {
      width: 50%;
      
      &.h-right {
        justify-content: flex-end;
      }
      &.h-left {
        justify-content: flex-start;
      }
      &.h-center {
        justify-content: center;
      }
    }
  }
  
  figure {
    
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    will-change: transform,opacity;
    transition: transform 1.24s cubic-bezier(.19,1,.22,1),opacity 1.24s cubic-bezier(.19,1,.22,1);
    
    display: flex;
    align-items: stretch;
    align-content: center;
    justify-content: center;
    
    .img {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      
      &.h-right {
        background-position: right center;
      }
      
      &.h-left {
        background-position: left center;
      }
      
      &.v-top {
        background-position: center top;
      }
      
      &.v-center {
        background-position: center center;
      }
      
      &.v-bottom {
        background-position: center bottom;
      }
    }
    
    &.is-visible {
      transform: translateY(0);
      opacity: 1;
    }
    
  }
  
}

[data-moving] {
  transform: translateY(30%);
  opacity: 0;
}

.mini-cart {

  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: height 0s ease-out 0.5s;
  
  .widget_shopping_cart_content {
    position: relative;
    
    .cart_list {
      @include clearfix;
      margin-bottom: 50px;
    }
    
    .mini_cart_item {
      
      position: relative;
      float: left;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dfdfdf;
      font-size: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      
      .intrinsic-ratio-box {
        width:  100%;
        float: left;
        margin-right: 20px;
      }
      
      img{
        float: left;
        width: 100%;
        max-width: 100px;
        margin-right: 20px;
      }
      
      .quantity {
        position: absolute;
        left: 120px;
        bottom: 20px;
        margin: 0;
        padding: 0;
        font-size: 16px;
        
      }
    }
    
    .remove {
      position: absolute;
      bottom: 24px;
      right: 0;
    }
    
    .total {
      
      font-size: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      
      .amount {
        float: right;
      }
    }
    
    .buttons {
      .button {
        display: inline-block;
        margin: 1em auto;
        vertical-align: top;
        border: 1px solid $black;
        width: 48%;
        height: 40px;
        background: #000;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        float: right;
        
        &:first-child {
          float: left;
          background: #fff;
          color: $black;
        }
      }
    }
    
  }
  
  .cart-title {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .close {
    
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    
    button {
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 30px;
    }
  }
  
  
 .mini-cart__bar {
     position: absolute;
     z-index: 2;
     top: 0;
     right: 0;
     width: 40%;
     height: 100%;
     padding-left: 40px;
     padding-right: 40px;
     background: #fff;
     overflow-y: scroll;
     transform: translateX(100%);
     transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
     
     @include media ($tablet){ 
       width: 80%;
     }
 } 
 
 &.is-visible {
   display: block;
   height: 100%;
   transition: none;
   
   .mini-cart__bar {
     transform: translateX(0);
   }
   
   .mini-cart__background { 
     opacity: .63;
   }
 }

 .mini-cart__background {
     position: absolute;
     z-index: 1;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: #020101;
     opacity: 0;
     transition: opacity .3s ease-out;
 }


}


.cart-collaterals {
  float: right;
      width: 100%;
      max-width: 480px;
}

.quantity {
  display: inline-block;
  margin: 1em 50px 1em 0;
  
  
  .inc {
    display: inline-block;
    width: 20px;
    height: 20px;
    
    i {
      color: $black;
    }
  }
  
  input {
    appearance: none;
    display: inline-block;
    height: 30px;
    width: 20px;
    border: none;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    
    &:focus {
      outline: none;
    }
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  appearance: none; 
  margin: 0; 
}

.remove {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  text-decoration: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 14px;
    height: 2px;
    background-color: #282828;
    transition: background-color .2s ease-out;
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 14px;
    height: 2px;
    background-color: #282828;
    transition: background-color .2s ease-out;
  }
}

input.button {
  appearance: none;
  display: inline-block;
  border: none;
  width: 140px;
  height: 40px;
  margin: 1em auto;
  vertical-align: top;
  line-height: 40px;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
}


.cart_item {
  
  
  
  .product-remove{
    width: 40px;
    
  }
  .product-thumbnail{
    .prod-thumbnail {
      width: 75px;
      display: inline-block;
    }
    
    img {
      max-width: 75px;
    }
    
    
  }
  .product-name{
    width: 40%;
    font-size: 14px;
    text-transform: uppercase;
  }
  .product-price{
    
  }
  .product-quantity{
    width: 80px;
    .quantity {
      margin: 0;
      width: 72px;
    }
  }
  .product-subtotal{
    
  }
}


.checkout-button--black {

      display: inline-block;
      margin: 1em auto;
      vertical-align: top;
      border: 1px solid $black;
      width: 48%;
      height: 40px;
      background: #000;
      color: #fff;
      line-height: 40px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      
      
  }
  
.edit {
  display: inline-block;
  margin: 1em auto;
  vertical-align: top;
  border: 1px solid $black;
  width: 160px;
  height: 40px;
  background: #000;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
}
  
  

  
  
/*.widget_shopping_cart_content {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: $white;
  z-index: 100;
  display: none;
}*/
.product-page{
  padding: 0 0 0 240px;
  
  @include media ($tablet){ 
    padding: 0;
  }
    
  .related-products {
    border-top: 1px solid #eee; 
    padding:40px 0 120px; 
    
    @include media ($tablet){ 
      padding:40px 32px 120px 32px;
      text-align: center;
    }
  }
  
  h2 {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
  }
  
  
  .product-images{
    margin-top: 30px;
    height: calc(100vh - 30px);
    
    @include media ($tablet){ 
      margin-top: 60px;
      height: auto;
    }
    
    @include media ($tablet){ 
      margin-top: 90px;
      height: auto;
    }
  }
  
  .images {
    
    height: 100%;
    width: 60%;
    float: left;
    overflow: hidden;
    
    @include media ($tablet){ 
      width: 100%;
      float: none;
      margin: 0 auto;
      height: 60vh;
    }
    
    .image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-align: right;
    }
    
    img {
      height: 100%;
      width: auto;
    }
  }
  
  .summary {
    width: 40%;
    float: left;
    max-width: 320px;
    padding-left: 50px;
    
    @include media ($tablet){ 
      
      @include clearfix;
      width: 100%;
      float: none;
      max-width: 100%;
      padding-left: 32px;
      padding-bottom: 40px;
      
      .product_title {
        max-width: 320px;
        float: left;
        
      }
      
      .product_meta {
        max-width: 320px;
        float: left;
        clear: left;
      }
      
      .onsale {
        float: left;
        clear: left;
      }
      
      [itemprop="offers"] {
        max-width: 320px;
        float: left;
        clear: both;
      }
      
      [itemprop="description"] {
        max-width: 320px;
        float: left;
        clear: left;
      }
      
      .stock {
        max-width: 320px;
        float: right;
        clear: right;
        margin-right: 32px;
        margin-top: -120px;
      }
      
      .cart {
        max-width: 320px;
        float: right;
        clear: right;
        margin-right: 32px;
        text-align: right;
        margin-top: -90px;
        
        .quantity {
          display: inline-block;
          margin: 0 auto;
        }
        
        .single_add_to_cart_button {
          display: block;
          margin: 20px auto 0;
        }
      }
      
      
      @include media ($mobile){ 
        
        @include clearfix;
        width: 100%;
        float: none;
        max-width: 100%;
        padding: 20px;
        
        .product_title {
          max-width: 320px;
          float: none;
          
        }
        
        .product_meta {
          max-width: 320px;
          float: none;
          clear: left;
        }
        
        .onsale {
          float: none;
          clear: left;
        }
        
        [itemprop="offers"] {
          max-width: 320px;
          float: none;
          clear: both;
        }
        
        [itemprop="description"] {
          max-width: 320px;
          float: none;
          clear: left;
        }
        
        .stock {
          max-width: 320px;
          float: none;
          clear: right;
          margin-right: auto;
          margin-top: auto;
        }
        
        .cart {
          max-width: 320px;
          float: none;
          clear: right;
          margin-right: auto;
          text-align: left;
          margin-top: auto;
          
          .quantity {
            display: block;
            margin: 0 ;
          }
          
          .single_add_to_cart_button {
            display: block;
            margin: 20px 0 0;
          }
        }
     }
      
    }

  
  
  
  
  
  
  
  
  
  
  
  }
  
}


.summary {
  
  font-size: 14px;
  font-weight: 300;
  padding-top: 40px;
    
  .product_title {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
  }
  
  .product_meta {
    font-size: 12px;
  }
  
  [itemprop="offers"]{
    display: inline-block;
  }
  
  .price {
    display: inline-block;
    
    del {
      .amount {
      font-weight: normal;
      }
    }
    
    ins {
      font-weight: bold;
      text-decoration: none;
    }
  }
  
  .amount {
    margin-right: 1em;
    font-weight: bold;
  }
  
  .onsale {
    display: inline-block;
    line-height: 30px;
    box-sizing: border-box;
    color: $white;
    background: $black;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 0 auto;
    position: relative;
    padding: 0 12px;  
    float: right;  
    
  }
  
  .stock {
    
  }
  
  
  .cart {
    
    .button {
      
    }
    
  }
  
}


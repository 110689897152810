  $hamburger-padding-x                       : 0 !default;
  $hamburger-padding-y                       : 0 !default;
  $hamburger-layer-width                     : 50px !default;
  $hamburger-layer-height                    : 2px !default;
  $hamburger-layer-spacing                   : 9px !default;
  $hamburger-layer-color                     : $black !default;
  $hamburger-layer-border-radius             : 0px !default;
  $hamburger-hover-opacity                   : 0.7 !default;
  $hamburger-hover-transition-duration       : 0.1s !default;
  $hamburger-hover-transition-timing-function: linear !default;
  
  $hamburger-hover-use-filter: false !default;
  $hamburger-hover-filter    : opacity(50%) !default;
 
  
  
  
  .hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;
  
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
  
    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  
    /*&:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-hover-filter;
      }
      @else {
        opacity: $hamburger-hover-opacity;
      }
    }*/
  }
  
  .hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
    
    @include media($mobile){
      width: 32px;
    }
  }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    
  
    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform, width, opacity;
      transition-duration: 0.51s;
      transition-timing-function: cubic-bezier(.91,.01,.6,.99);
      
      @include media($mobile){
        width: 32px;
      }
      
    }
  
    &::before,
    &::after {
      content: "";
      display: block;
    }
  
    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  
    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }
  
  
  .hamburger--arrowalt {
      .hamburger-inner {
        &::before {
          transition: top 0.51s 0.51s ease,
                      transform 0.51s cubic-bezier(.91,.01,.6,.99);
        }
  
        &::after {
          transition: bottom 0.51s 0.51s ease,
                      transform 0.51s cubic-bezier(.91,.01,.6,.99);
        }
      }
  
      &.is-active {
        .hamburger-inner {
          
          & {
            transform: translate3d(0, 0, 0);
            width: 0;
          }
        
          &::before {
            top: 0;
            transform: translate3d($hamburger-layer-width * -0.2, -17px, 0) rotate(-45deg) scale(1, 1);
            transition: top 0.2s ease,
                        transform 0.51s 0.2s cubic-bezier(.91,.01,.6,.99);
          }
  
          &::after {
            bottom: 0;
            transform: translate3d($hamburger-layer-width * -0.2, 17px, 0) rotate(45deg) scale(1, 1);
            transition: bottom 0.2s ease,
                        transform 0.51s 0.2s cubic-bezier(.91,.01,.6,.99);
          }
          
          
          @include media($mobile){
            &::before {
                      top: 0;
                      transform: translate3d($hamburger-layer-width * -0.2, -11px, 0) rotate(-45deg) scale(1, 1);
                      transition: top 0.2s ease,
                                  transform 0.51s 0.2s cubic-bezier(.91,.01,.6,.99);
                    }
            
                    &::after {
                      bottom: 0;
                      transform: translate3d($hamburger-layer-width * -0.2, 11px, 0) rotate(45deg) scale(1, 1);
                      transition: bottom 0.2s ease,
                                  transform 0.51s 0.2s cubic-bezier(.91,.01,.6,.99);
                    }
          }
          
        }
      }
      
      
      
    }
  
  .hamburger--arrow.is-active {
      .hamburger-inner {
        & {
          transform: translate3d(0, 0, 0);
          width: 0;
        }
        
        &::before {
          transform: translate3d(($hamburger-layer-width * -0.2) , -6px, 0) rotate(-45deg) scale(1, 1);
          width: $hamburger-layer-width;
        }
  
        &::after {
          transform: translate3d(($hamburger-layer-width * -0.2) , 6px, 0) rotate(45deg) scale(1, 1);
          width: $hamburger-layer-width;
        }
      }
    }
  

  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.14s ease,
                    opacity 0.1s ease;
      }

      &::after {
        transition: bottom 0.1s 0.14s ease,
                    transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        width: $hamburger-layer-width;
        
        background-color: $white;

        &::before {
          top: 0;
          opacity: 0;
          background-color: $white;
          transition: top 0.1s ease,
                      opacity 0.1s 0.14s ease;
        }

        &::after {
          bottom: 0;
          background-color: $white;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease,
                      transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
// Welcome to Iconode
// You can use this file in your project instead of .css file, and build it with other your less files.

@import "variables";
@import "mixins";
@import "functions";
@import "generals";
@import "icons";

[class*="icono-"] {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-style: normal;
    color: $maincolor;
    text-align: left;
    text-indent: -9999px;
    direction: ltr;
    &:before, &:after{
        content:'';
        pointer-events: none;
    }
    &[class*="Circle"]{
        @extend .icono-stroke;
        border-radius: 50%;
        width: $U30;
        height: $U30;
        margin: $U2;
    }
    &[class*="Square"]{
        @extend .icono-stroke;
        border-radius: $U4;
        width: $U30;
        height: $U30;
        margin: $U2;
    }
    &, & *{
        box-sizing: border-box;
    }
}

header {
  
  text-align: right;
  
  &.about-header {
    text-align: left;
    height: 100vh;
    
    .text-col { 
      width: 42%;
      
      @include media ($tablet){ 
        width: 100%;
      }
    }
    
    .image-col {
      text-align: left;
    }
    
    @include media ($tablet){ 
     height: auto;
    }
  }
  
  .text-col {
    
    padding: 210px 40px 40px 0;
    text-align: right;
    display: inline-block;
    vertical-align: top;
    
    @include media ($tablet){ 
      width: 100%;
    }
    
    .text-wrapper {
      //max-width: 250px;
      display: inline-block;
      text-align: left;
      
      width: 65%;
      margin-left: 35%;
      
      @include media ($mobile){ 
       width: 100%;
       margin-left: 0;
       
      };
      
    }
    
    @include media ($tablet){ 
     text-align: left;
     padding: 140px 40px 40px 32px;
     
    };
    
    @include media ($mobile){ 
     padding: 90px 20px 40px 20px;
     
    };
    
    
  }
  
  .column {
    width: 58%;
    height: auto;
    
    @include media ($tablet){
      width: 100%;
      float: left;
    }
  }
  
  .image-col {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 58%;
    height: auto;
    height: 100%;
    
    img {
      width: auto;
      height: 100%;
      display: block;
      
      @include media ($tablet){
        width: 100%;
        height: auto;
      };
    };
    
    @include media ($tablet){
      width: 100%;
      float: left;
    }
    
  }
  
  .fixed-col {
    position: fixed;
    width: 58%;
    top: 0;
    right: 0;
    text-align: right;
    
    @include media ($tablet){
      position: relative;
      width: 100%;
    }
  }
  
  &.collection-header {
    
  }
  
  .news-nav-bar {
    display: none;
    @include media ($tablet){ 
    
      @include clearfix;
      display: block;
      width: 100%;
      padding: 0 32px 24px;
      
    }
    
    @include media ($mobile){ 
      padding: 0 20px 24px;
    }
    
    .mobile-next-article {
      
      display: inline-block;
      float: right;
      
      &.disabled {
        opacity: .3;
      }
      
      .arrow{
        display: block;
        width: 32px;
        height: 32px;
        transform: rotate(-90deg);
      }
    }
    
    .mobile-prev-article {
      
      display: inline-block;
      float: left;
      
      &.disabled {
        opacity: .3;
      }
      
      .arrow{
        display: block;
        width: 32px;
        height: 32px;
        transform: rotate(90deg);
      }
    }
    
    
  }
  
  .next-article {
    position: absolute;
    right: 20px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    
    @include media ($tablet){ 
      display: none;
    }
    
    .arrow{
      display: block;
      width: 90px;
      height: 90px;
      transform: rotate(-90deg);
    }
    
    &.disabled {
      display: none;
    }
  }
}
.box {
  
  display: block;
  float: left;
  margin-bottom: 9vw;
  margin-left: 7vw;
  
  >div {
    width: 100%;
    height: 100%;
  }
  
  @include media ($tablet){ 
    margin: 0;
  };
  
  
  transition: opacity 1s cubic-bezier(.59,.01,.28,1) , transform 1s cubic-bezier(.59,.01,.28,1);
  opacity: 0;
  transform: translate3d(0, 3vw, 0);

  
  &.is-visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  &.box--choke{
    margin-bottom: 0;
  }

    
  &.indent-top--s {
    margin-top: 6.75vw;
    
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
  }
  
  &.indent-top--m {
    margin-top: 18vw;
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
  }
  
  &.indent-top--l {
    margin-top: 30vw;
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
  }
  
  
  
  &.indent--xs {
    margin-left: 0;  
    
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
  
  }
  
  &.indent--s {
    margin-left: 4.5vw; 
    
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
       
    &.box--pull {
      margin-right: 4.5vw;  
      
      @include media ($tablet){ 
        margin: 2vw 2%;
      }
    }
  }
  
  &.indent--m {
    margin-left: 15vw;
    
    @include media ($tablet){ 
      margin: 2vw 2%;
    }
    
    &.box--pull {
      margin-right: 15vw; 
      
      @include media ($tablet){ 
        margin: 2vw 2%;
      }
    }
  }
  
  &.indent--l {
    margin-left: 28vw;
    
    @include media ($tablet){ 
      margin: 0;
    }
    
    &.box--pull {
      margin-right: 28vw;  
      
    }
  }
  
  &.box--pull {
    float: right;
    
    @include media ($tablet){ 
      float: left;
    }
  }
  
  
  &.port--s {
    width: 22vw;
    height: 31.5vw;
    
    @include media ($tablet){ 
      width: 46%;
      margin: 2vw 2%;
      height: auto;
    }
    
  }
  
  &.port--m {
    width: 31.5vw;
    height: 45vw;
    @include media ($tablet){ 
      width: 46%;
      margin: 2vw 2%;
      height: auto;
    }
  }
  &.port--l {
    width: 45vw;
    height: 63vw;
    @include media ($tablet){ 
      width: 46%;
      margin: 2vw 2%;
      height: auto;
    }
  }
  

  &.box--s{
    width: 50vw;
    height: 33vw;
    
    @include media ($tablet){ 
      width: 96%;
      margin: 2vw 2%;
      height: auto;
    }
  }
  
  &.box--m {
    width: 70vw;
    height: 47vw;
    
    @include media ($tablet){ 
      width: 96%;
      margin: 2vw 2%;
      height: auto;
    }
  }
  
  &.box--l {
    width: 86vw;
    height: 57vw;
    
    @include media ($tablet){ 
      width: 96%;
      margin: 2vw 2%;
      height: auto;
    }
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  @include media ($tablet){ 
    &[class*="port"] {
      &:nth-child(3n) {
        margin-top: 25vw;
      }
      &:nth-child(3n + 1) {
        margin-top: 15vw;
      }
      
      
      
    };
  };
  
}
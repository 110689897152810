//home header

.intro-header {

  height: 100vh;
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: block;
  padding: 32px;
  text-align: right;
  box-sizing: border-box;
  
  img {
    width: 100%;
  }
  
  .intro-container{
    background: url('/images/home.jpg') no-repeat right top;
    background-size: contain;
    
    height: 100%;
    width: 100%;
  }
}
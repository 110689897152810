body, html {
	/* prevent horizontal scrolling */
	overflow-x: hidden;
}

body {
  background: $grey;
}

main {
	position: relative;
	z-index: 2;
	background: $white;
  min-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
		
	/* Force Hardware Acceleration in WebKit */
	transform: translateZ(0) ;
	backface-visibility: hidden;

  transition: transform .31s cubic-bezier(.91,.01,.6,.99);
  
	.has-menu & {
		//transform: translateX(440px);
		transform: translateX(240px);
		transition: transform .31s cubic-bezier(.91,.01,.6,.99);
		
		@include media($mobile){
		  transform: translateX(50vw);
		}
	}
	
	.has-collection &{
	  transform: translateX(440px);
	  transition: transform .31s cubic-bezier(.91,.01,.6,.99);
	  
	  @include media($mobile){
	    transform: translateX(100vw);
	  }
	}
}

#content {
  transform: translate3d(100%, 0, 0);
  min-height: calc(100vh - 188px);
 
}

.shop-body {
  #content {
    transform: translate3d(0, 0, 0);
    padding-bottom: 50px;
  }
}

.nav {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	visibility: hidden;
  transition: visibility 0s .7s;
  transform: translateZ(0) ;
  backface-visibility: hidden;
  
  @include media($mobile){
    width: 100vw;
  };

	.navigation-wrapper {
		/* all navigation content */
		height: 100%;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		
		/* Force Hardware Acceleration in WebKit */
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;

    transform: translateX(-100px);
    transition: transform .31s;
    transition-timing-function: cubic-bezier(.91,.01,.6,.99);
    
    @include media($mobile){
      transform: translateX(0);
    };

	}

	.has-menu & {
		visibility: visible;
		transition: visibility 0s 0s;
		
		.navigation-wrapper {
		  transform: translateX(0);
		  transition: transform .31s ;
		  transition-timing-function: cubic-bezier(.91,.01,.6,.99);
		}
	}

}


/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #000;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */


.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.backtotop {
  width: 50px;
  height: 50px;
  background: rgba(#eeeeee, .8);
  color: $black;
  display: block;
  position: fixed;
  bottom: 30px;
  right: 30px;
  visibility: hidden;
  z-index: 90;
  opacity: 0;
  transform: translate3d(0,90px,0);
  transition: transform .5s ease-in, opacity .5s ease-in, visibility .5s;
  
  
  &.is-visible {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0,0,0);
  ;
  }
  
  .backtotop-svg{
    width: 16px;
    height: 16px;
    display: block;
    margin:17px;
  }
  
  
}

.woocommerce-content {
  padding: 70px 110px 0 240px;
  
  .woocommerce {
    @include clearfix;
  }
  
  @include media ($tablet){ 
    padding: 140px 32px 0;
  };
  
  @include media ($mobile){ 
    padding: 140px 20px 0;
  };
  
  h2 {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
  }
  
  h3 {
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
  }
}

.page-title{
  
}

.styled-select {
   background:#000 url(/images/select-arrow.png) no-repeat 96% 0;
   height: 30px;
   overflow: hidden;
   width: 240px;
   display: inline-block;
   vertical-align: middle;
   display: inline-block;
   margin-left: 30px;
   vertical-align:  middle;
   box-sizing: border-box;
}

.styled-select select {
   color: $white;
   background: transparent;
   border: none;
   font-size: 12px;
   font-weight: 400;
   letter-spacing: .06em;
   height: 30px;
   padding: 5px; /* If you add too much padding here, the options won't show in IE */
   width: 268px;
   border-radius: 5px;
   
   &:focus {
     outline: none;
   }
}



.products{
  
  display: flex; 
  flex-wrap: wrap;
  justify-content: flex-start;
  
  @include media ($tablet){ 
   justify-content: center;
  }
  
}

@keyframes seconds {
  0% {
    transform: translate3d(0, 0, 0); 
  }
  100% {
    transform: translate3d(100%, 0, 0); 
  }
}

.woo-notice {
  position: fixed;
  right: 0;
  top: 30px;
  z-index: 999;
  height: 160px;
  width: 60%;
  background: $grey;
  color: $black;
  display: table;
  text-align: center;
  margin: 0;
  padding: 0 10%;
  font-size: 16px;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  
  a {
    display: block;
    text-align: center;
    margin: 0 auto;
    text-decoration: underline;
  }
  
  .ui__remove {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    
    .icono-cross {
      color: #444;
    }
  }
  
  li {
    margin: 0;
    padding: 0;
  }
  
  .notice-wrapper {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    
    a {
      color: $black;
    }
  }
}



.woocommerce-MyAccount-navigation {
  
  li {
    display: inline-block;
    margin-right: 16px;
    
    a {
      text-transform: uppercase;
      letter-spacing: .06em;
      font-size: 12px;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    
    
    &.is-active {
    a {
      text-decoration: underline;
    }
    }
  }
  
}


.woocommerce-MyAccount-content {
  max-width: 680px;
  padding-top: 60px;
  
  header {
    text-align: left;
  }
}





$woocommerce:   	#a46497;
$green:         	#7ad03a;
$red:           	#a00;
$orange:        	#ffba00;
$blue:          	#2ea2cc;

$primary:           #a46497;                                    // Primary colour for buttons (alt)
$primarytext:       desaturate(lighten($primary, 50%), 18%);    // Text on primary colour bg

$secondary:         desaturate(lighten($primary, 40%), 21%);    // Secondary buttons
$secondarytext:     desaturate(darken($secondary, 60%), 21%);   // Text on secondary colour bg

$highlight:         adjust-hue($primary, 150deg);               // Prices, In stock labels, sales flash
$highlightext:      desaturate(lighten($highlight, 50%), 18%);  // Text on highlight colour bg

$contentbg:         #fff;                                       // Content BG - Tabs (active state)
$subtext:           #777;                                       // small, breadcrumbs etc


/**
	 * Forms
	 */
	form {
		.form-row {
			padding: 3px;
			margin: 0 0 6px;

			[placeholder]:focus::-webkit-input-placeholder {
				-webkit-transition: opacity 0.5s 0.5s ease;
				-moz-transition: opacity 0.5s 0.5s ease;
				transition: opacity 0.5s 0.5s ease;
				opacity: 0;
			}

			label {
				line-height: 2;
			}

			label.hidden {
				visibility: hidden;
			}

			label.inline {
				display: inline;
			}

			select {
				cursor: pointer;
				margin: 0;
			}

			.required {
				color: red;
				font-weight: 700;
				border: 0;
			}

			.input-checkbox {
				display: inline;
				margin: -2px 8px 0 0;
				text-align: center;
				vertical-align: middle;
			}
			
			input.input-text {
			  height: 40px;
			  font-size: 90%;
			}

			input.input-text,
			textarea {
				box-sizing: border-box;
				width: 100%;
				margin: 0;
				outline: 0;
				line-height: 1;
			}

			textarea {
				height: 4em;
				line-height: 1.5;
				display: block;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
				box-shadow: none;
			}

			.select2-container {
				width: 100%;
				line-height: 2em;
			}

			&.woocommerce-invalid {
				label {
					color: $red;
				}
				.select2-container,
				input.input-text,
				select {
					border-color: $red;
				}
			}

			&.woocommerce-validated {
				.select2-container,
				input.input-text,
				select {
					border-color: $green - #111;
				}
			}

			::-webkit-input-placeholder {
				line-height: normal;
			}

			:-moz-placeholder {
				line-height: normal;
			}

			:-ms-input-placeholder {
				line-height: normal;
			}
		}
	}

	form.login,
	form.checkout_coupon,
	form.register {
		border: 1px solid darken( $secondary, 10% );
		padding: 20px;
		margin: 2em 0 2em 0px;
		text-align: left;
		border-radius: 5px;
	}

	ul#shipping_method {
		list-style: none outside;
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: .25em 0 .25em 22px;
			text-indent: -22px;
			list-style: none outside;
			input {
				margin: 3px 0.5ex;
			}
			label {
				display: inline;
			}
		}

		.amount {
			font-weight: 700;
		}
	}

	p.woocommerce-shipping-contents {
		margin: 0;
	}
	
	
	
	
	
	
	  table.shop_attributes {
			border: 0;
			border-top: 1px dotted rgba(0,0,0,0.1);
			margin-bottom: 1.618em;
			width: 100%;
	
			th {
				width: 150px;
				font-weight: 700;
				padding: 8px;
				border-top: 0;
				border-bottom: 1px dotted rgba(0,0,0,0.1);
				margin: 0;
				line-height: 1.5;
			}
	
			td {
				font-style: italic;
				padding: 0;
				border-top: 0;
				border-bottom: 1px dotted rgba(0,0,0,0.1);
				margin: 0;
				line-height: 1.5;
	
				p {
					margin: 0;
					padding: 8px 0;
				}
			}
	
			.alt td,
			.alt th {
				background: rgba(0,0,0,0.025);
			}
		}
	
		table.shop_table {
			border-bottom: 1px solid rgba(0,0,0,0.1);
			border-top: none;
			margin: 0px -1px 24px 0px;
			text-align: left;
			width: 100%;
			border-collapse: separate;
			border-radius: 0;
	
			th {
				font-weight: 700;
				padding: 9px 12px;
			}
	
			td {
				border-top: 1px solid rgba(0,0,0,0.1);
				padding: 6px 12px;
				vertical-align: middle;
	
				small {
					font-weight: normal;
				}
			}
	
			tbody:first-child {
				tr:first-child {
					th,
					td {
						border-top: 0;
					}
				}
			}
	
			tfoot td,
			tfoot th,
			tbody th {
				font-weight: 700;
				border-top: 1px solid rgba(0,0,0,0.1);
			}
		}
	
		table.my_account_orders {
			font-size: 0.85em;
	
			th,
			td {
				padding: 4px 8px;
				vertical-align: middle;
			}
	
			.button {
				white-space: nowrap;
			}
	
			.order-actions {
				text-align: right;
	
				.button {
					margin: .125em 0 .125em .25em;
				}
			}
		}
	
		td.product-name {
			dl.variation {
				margin: .25em 0;
				@include clearfix();
	
				dt,
				dd {
					display: inline-block;
					float: left;
					margin-bottom: 1em;
				}
	
				dt {
					font-weight: 700;
					padding: 0 0 .25em 0;
					margin: 0 4px 0 0;
					clear: left;
				}
	
				dd {
					padding: 0 0 .25em 0;
	
					p:last-child {
						margin-bottom: 0;
					}
				}
			}
	
			p.backorder_notification {
				font-size: 0.83em;
			}
		}
	
		td.product-quantity {
			min-width: 80px;
		}
		
		
		
.col2-set {
		@include clearfix();
		width: 100%;

		.col-1 {
			float: left;
			width: 48%;
		}
		.col-2 {
			float: right;
			width: 48%;
		}
	}



	form {
	
	  fieldset {
	    border: none;
	    margin: 0;
	    padding: 0;
	    
	    legend {
	      margin: 0;
	      padding: 3px;
	      
	    }
	  }
	  
		.form-row {
			@include clearfix();

			label {
				display: block;

				&.checkbox {
					display: inline;
				}
			}

			select {
				width: 100%;
			}

			.input-text {
				box-sizing: border-box;
				width: 100%;
			}
		}

		.form-row-first,
		.form-row-last {
			float: left;
			width: 47%;
			overflow: visible;
		}

		.form-row-last {
			float: right;
		}

		.form-row-wide {
			clear: both;
		}
	}

	#payment {
		.form-row {
			select {
				width: auto;
			}
		}

		.wc-terms-and-conditions, .terms {
			text-align: left;
			padding: 0 1em 0 0;
			float:left;
		}

		#place_order {
			float: right;
		}
	}
	
	
	@include media($mobile){
	  .woocommerce,
	  .woocommerce-page {
	  
	  	table.shop_table_responsive {
	  		thead {
	  			display: none;
	  		}
	  
	  		tbody {
	  			tr:first-child {
	  				td:first-child {
	  					border-top: 0;
	  				}
	  			}
	  
	  			th {
	  				display: none;
	  			}
	  		}
	  
	  		tr {
	  			display: block;
	  
	  			td {
	  				display: block;
	  				text-align: right !important; // Important to overwrite order status inline styling
	          width: 100%;
	          
	  				&.order-actions {
	  					text-align: left !important;
	  				}
	  
	  				&:before {
	  					content: attr(data-title) ': ';
	  					font-weight: 700;
	  					float: left;
	  				}
	  
	  				&.product-remove,
	  				&.actions {
	  					&:before {
	  						display: none;
	  					}
	  				}
	  			}
	  
	  			&:nth-child(2n) {
	  				td {
	  					background-color: rgba(0,0,0,0.025);
	  				}
	  			}
	  		}
	  	}
	  
	  	table.my_account_orders {
	  		tr {
	  			td {
	  				&.order-actions {
	  					text-align: left;
	  
	  					&:before {
	  						display: none;
	  					}
	  
	  					.button {
	  						float: none;
	  						margin: .125em .25em .125em 0;
	  					}
	  				}
	  			}
	  		}
	  	}
	  
	  
	  
	  	/**
	  	 * Cart
	  	 */
	  	table.cart,
	  	#content table.cart {
	  		.product-thumbnail {
	  			display: none;
	  		}
	  
	  		td.actions {
	  			text-align: left;
	  
	  			.coupon {
	  				float: none;
	  				@include clearfix();
	  				padding-bottom: .5em;
	  
	  				input,
	  				.button,
	  				.input-text {
	  					width: 48%;
	  					box-sizing: border-box;
	  				}
	  
	  				.input-text + .button,
	  				.button.alt {
	  					float: right;
	  				}
	  			}
	  
	  			.button {
	  				display: block;
	  				width: 100%;
	  			}
	  		}
	  	}
	  
	  	.cart-collaterals {
	  		.cart_totals,
	  		.shipping_calculator,
	  		.cross-sells {
	  			width: 100%;
	  			float: none;
	  			text-align: left;
	  		}
	  	}
	  
	  	/**
	  	 * Checkout
	  	 */
	  	&.woocommerce-checkout {
	  		form.login {
	  			.form-row {
	  				width: 100%;
	  				float: none;
	  			}
	  		}
	  	}
	  
	  	#payment {
	  		.terms {
	  			text-align: left;
	  			padding: 0;
	  		}
	  
	  		#place_order {
	  			float: none;
	  			width: 100%;
	  			box-sizing: border-box;
	  			margin-bottom: 1em;
	  		}
	  	}
	  
	  	/**
	  	 * Account
	  	 */
	  	.lost_reset_password {
	  		.form-row-first,
	  		.form-row-last {
	  			width: 100%;
	  			float: none;
	  			margin-right: 0;
	  		}
	  	}
	  }
	  
	  .woocommerce-account {
	  	.woocommerce-MyAccount-navigation,
	  	.woocommerce-MyAccount-content {
	  		float: none;
	  		width: 100%;
	  	}
	  }
	  
	  /**
	   * General layout
	   */
	  .col2-set {
	  	.col-1,
	  	.col-2 {
	  		float: none;
	  		width: 100%;
	  	}
	  }
	  
	  
	  
	  
	  form.login {
	  	.form-row {
	  		width: 100%;
	  		float: none;
	  	}
	  }
	  
	  
	  form {
	    .form-row-first,
	    .form-row-last {
	    	width: 100%;
	    	float: none;
	    	margin-right: 0;
	    }
	  }
	  
	}
	
	
$white: #ffffff;
$grey: #eeeeee;
$darkgrey: #bbbbbb;
$black: #000000;


$fontSize-root: 62.5%;
$body-font: 'Roboto', sans-serif;

$mobile: new-breakpoint(max-width 600px 16);
$tablet: new-breakpoint(max-width 980px 16);
$large: new-breakpoint(max-width 1180px 16);

/* 1280 / 1010 / 600   */

$page-padding: 10.54%;  /*  135px / 1280     */
$left: 26,56%;     /*  340px / 1280px   */

/*font sizes

15px   1
20px   1.25
30px   2
50px   3.3 

*/
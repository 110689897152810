.shop-footer {
  
  background: $white;
  
  padding:20px 0 50px 240px;
  
  
  ul {
    
    padding-top: 30px;
    border-top: 1px solid #eee;
  
  }
  
  @include media($tablet){
    padding:50px 32px;
  };
  
  @include media($mobile){
    padding:50px 20px;
  }
}


